<template>
  <div>
    <div class="sctp-container sctp-pad-tb20">
      <div class="" style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>兼职中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-t10">
        <condition
            :value="conditionDefaultValue"
            @select="onConditionSelect"
            :data="conditionData"
        ></condition>
      </div>
      <el-row :gutter="10">
        <el-col :span="18">
          <div class="sctp-bg-white sctp-pad10 sctp-mar-t10 sctp-flex sctp-flex-sb">
            <div class="flex flex-sb">
              <el-button-group>
                <el-button size="mini">
                  综合
                </el-button>
                <el-button size="mini"
                           @click="orderByClick('expected_salary')"
                >期望薪资
                  {{
                    orderByStatus('expected_salary') === 0 ? '' : orderByStatus('expected_salary') > 0
                      ? '▲' : '▼'
                  }}
                </el-button>
              </el-button-group>

              <el-select size="mini" @change="partTimeConfig.loadData"
                         v-model="partTimeConfig.searchParams.workingYear"
                         style="width: 120px" placeholder="工作年限">
                <el-option
                    v-for="item in searchCondition.workingYearsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <div class="price-input-container">
                <el-input size="mini" type="number" v-model="partTimeConfig.searchParams.minPrice" @change="blur1">
                  <div slot="prefix">¥</div>
                </el-input>
                ~
                <el-input size="mini" type="number" v-model="partTimeConfig.searchParams.maxPrice" @change="blur2">
                  <div slot="prefix">¥</div>
                </el-input>
                <el-button class="mg-l5" @click="partTimeConfig.loadData" size="mini">确认</el-button>
              </div>
            </div>
            <div>
              <el-input size="mini" placeholder="职位/姓名" v-model="partTimeConfig.searchParams.name"
                        :style="{width:'auto'}">
                <el-button @click="partTimeConfig.loadData" slot="append"
                           icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </div>
          <el-row :gutter="10" class="sctp-mar-t10">
            <template v-if="partTimeConfig.data && partTimeConfig.data.length > 0">
              <template v-for="job in partTimeConfig.data">
                <a target="_blank" :href="`/parttime/view/${job.id}`" :key="job.id">
                  <el-col :span="12" class="sctp-mar-b10">
                    <div class="sctp-pad-lr15 sctp-pad-tb10 sctp-bg-white site-tag"
                         style="height: 216px;">
                      <div class="sctp-flex pr">
                        <el-image
                            :src="job.avatar"
                            style="height: 80px;width: 80px;border-radius: 100%;overflow: hidden;margin-right: 15px;"
                            fit="cover"
                            :lazy="true"
                        ></el-image>
                        <div class="sctp-flex-item-1 sctp-flex sctp-flex-column sctp-flex-sb"
                             style="height: 82px;">
                          <div class="fz-18 sctp-ellipsis--l2">
                            <span>{{ job.president }}</span>
                          </div>
                          <div>
                            <span class="sctp-pad-r20">{{ job.account }}</span>
                            <span v-if="getCity(job.city)"><i class="el-icon-location-outline"></i>{{
                                getCity(job.city)
                              }}</span></div>
                          <div class="flex flex-sb">
                            <div class="sctp-color-main fz-12">¥{{
                                job.expectedSalary
                              }}/{{ job.salaryUnit.replace('按', '') }}
                            </div>
                            <div v-if="job.workingYear">
                              {{ job.workingYear }}年
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="divider"></div>
                      <div class="sctp-font-12 sctp-lh-medium">
                        <div class="sctp-pad-tb5">
                          最擅长技能：
                          <el-tag class="sctp-mar-r5" type="success"
                                  size="mini"
                                  effect="plain"
                                  v-for="skill in job.skillList"
                                  :key="skill">
                            {{ skill }}
                          </el-tag>
                        </div>
                        <div class="sctp-mar-tb5 rich-text sctp-ellipsis--l2" style="height: 36px;">
                          简介：<span class="rich-text" v-html="job.aboutMySelf"></span>
                        </div>
                      </div>
                      <div class="sctp-flex sctp-flex-sb">
                        <div>
                          <template v-if="job.city">
                            <i class="el-icon-location"></i>
                            {{ getCity(job.city) }}
                          </template>
                        </div>
                        <div>
                          <el-tag
                              size="mini"
                              type="warning"
                              effect="dark"
                          >
                            查看详情
                          </el-tag>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </a>
              </template>
            </template>
            <template v-else>

            </template>
          </el-row>
          <div class="sctp-pad-tb10 sctp-tr sctp-bg-white sctp-mar-t5">
            <el-pagination
                :current-page="partTimeConfig.pageConfig.page"
                :page-size="partTimeConfig.pageConfig.pageSize"
                @size-change="partTimeConfig.pageConfig.handleSizeChange"
                @current-change="partTimeConfig.pageConfig.handleCurrentChange"
                :page-sizes="partTimeConfig.pageConfig.pageSizes"
                layout="prev, pager, next, total, sizes"
                :total="partTimeConfig.pageConfig.total">
            </el-pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="sctp-mar-t10 sctp-bg-white">
            <div class="sctp-bg-white">
              <div class="pad-tb15">
                <div class="slider-card-title">推荐人才</div>
              </div>
            </div>
            <template v-for="(job,index) in partTimeConfig.recommends">
              <a target="_blank" :href="`/parttime/view/${job.id}`" :key="job.id">
                <div class="sctp-pad-lr15 sctp-pad-tb10 sctp-bg-white">
                  <div class="sctp-flex pr">
                    <el-image
                        :src="job.avatar"
                        style="height: 80px;width: 80px;border-radius: 100%;overflow: hidden;margin-right: 15px;"
                        fit="cover"
                        :lazy="true"
                    ></el-image>
                    <div class="sctp-flex-item-1 sctp-flex sctp-flex-column sctp-flex-sb">
                      <div class="fz-12 fc-info">
                        <span>{{ job.president }}</span>
                      </div>
                      <div>
                        <span class="sctp-pad-r20">{{ job.account }}</span>
                        <span v-if="getCity(job.city)"><i class="el-icon-location-outline"></i>{{
                            getCity(job.city)
                          }}</span></div>
                      <div>
                      <span class="sctp-color-main fz-12">¥{{
                          job.expectedSalary
                        }}/{{ job.salaryUnit.replace('按', '') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <template v-if="index !== partTimeConfig.recommends.length - 1">
                <div class="divider"></div>
              </template>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {partTime,common} from "../../../apis";
import area from "../../../resources/js/area";

export default {
  name: "center",
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      searchForm:{
        orderBy:null
      },
      searchCondition: {
        workingYearsList: [
          {label: '全部', value: null},
          {label: '一年以内', value: '一年以内'},
          {label: '1年', value: '1'},
          {label: '2年', value: '2'},
          {label: '3年', value: '3'},
          {label: '4年', value: '4'},
          {label: '5年', value: '5'},
          {label: '6年', value: '6'},
          {label: '7年', value: '7'},
          {label: '8年', value: '8'},
          {label: '9年', value: '9'},
          {label: '10年', value: '10'},
          {label: '十年以上', value: '十年以上'},
        ],
        skills: [],
      },
      partTimeConfig: {
        searchParams: {
          workingYear: null,
          name: null,
          provinceCode: null,
          cityCode: null,
          skillId: null,
          minPrice: null,
          maxPrice: null,
        },
        data: [],
        recommends: [],
        loadCondition: () => {
          partTime.loadCondition().then(res => {
            let {data} = res;
            Object.keys(data).forEach(key => {
              this.searchCondition[key] = data[key];
            })
          });
        },
        loadRecommend: () => {
          partTime.loadRecommend().then(res => {
            let {data} = res;
            this.partTimeConfig.recommends = data;
          })
        },
        loadData: () => {
          let {pageConfig: {page, pageSize}, searchParams} = this.partTimeConfig;
          partTime.list({
            page: page,
            limit: pageSize,
            workingYear: searchParams.workingYear,
            name: searchParams.name,
            provinceCode: searchParams.provinceCode,
            cityCode: searchParams.cityCode,
            orderBy:this.searchForm.orderBy,
            skillId: searchParams.skillId,
            minPrice: searchParams.minPrice,
            maxPrice: searchParams.maxPrice,
          })
          .then(res => {
            console.log(res)
            let {data = [], count = 0} = res;
            this.partTimeConfig.data = data;
            this.partTimeConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          pageSize: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (pageSize) => {
            this.partTimeConfig.pageConfig.pageSize = pageSize;
            this.partTimeConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.partTimeConfig.pageConfig.page = page;
            this.partTimeConfig.loadData();
          }
        }
      }
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.loadRouterData();
        this.partTimeConfig.loadData();
      }
    }
  },
  methods: {
    blur1(){
      if(this.partTimeConfig.searchParams.minPrice!=null) {

        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.partTimeConfig.searchParams.minPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.partTimeConfig.searchParams.minPrice=0
          return
        }
        let a = this.partTimeConfig.searchParams.maxPrice * 1;
        let b = this.partTimeConfig.searchParams.minPrice * 1;

        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.partTimeConfig.searchParams.minPrice=0
          return
        }
        if(a<b&&this.partTimeConfig.searchParams.maxPrice!=null)
        {
          this.$alert("最低价格不能超过最高价格")
          this.partTimeConfig.searchParams.minPrice=0
        }
      }
    },
    blur2(){
      if(this.partTimeConfig.searchParams.maxPrice!=null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.partTimeConfig.searchParams.maxPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.partTimeConfig.searchParams.maxPrice=0
          return
        }

        let a = this.partTimeConfig.searchParams.maxPrice * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.partTimeConfig.searchParams.maxPrice=null
          return
        }
        let b = this.partTimeConfig.searchParams.minPrice * 1;
        if(a<b&&this.partTimeConfig.searchParams.minPrice!=null)
        {
          this.$alert("最高价格不能低于最低价格")
          this.partTimeConfig.searchParams.maxPrice=0
        }
      }
    },
    onConditionSelect({label, target}) {
      const {value} = target;
      switch (label) {
        case '省份':
          this.partTimeConfig.searchParams.provinceCode = value;
          this.partTimeConfig.searchParams.cityCode = null;
          this.partTimeConfig.loadData();
          break;
        case '城市':
          this.partTimeConfig.searchParams.cityCode = value;
          this.partTimeConfig.loadData();
          break;
        case '技能':
          this.partTimeConfig.searchParams.skillId = value;
          this.partTimeConfig.loadData();
          break;
        default:
          break
      }
    },
    loadRouterData() {
      let {search} = this.getQuery();
      this.partTimeConfig.searchParams.name = search || null;
    },
    orderByClick(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        } else if (ret === -1) {
          result = null;
        }
      }
      this.searchForm.orderBy = result;
      this.partTimeConfig.loadData();
    },
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let orderBy = this.searchForm.orderBy;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    },
    conditionDefaultValue() {
      return {
        省份: null,
        城市: null,
        技能: null
      }
    },
    conditionData() {
      const result = [];
      let {skills} = this.searchCondition;
      let {provinceCode, cityCode} = this.partTimeConfig.searchParams;
      result.push({
        label: '省份',
        values: [{
          title: '全部',
          value: null,
        }].concat(area.options
        .map(item => {
          item.title = item.label;
          return item
        }))
      });
      result.push({
        label: '城市',
        values: [{
          title: '全部',
          value: null,
        }].concat(area.options
        .filter(item => !provinceCode || provinceCode === item.value)
        .map(item => item.children || [])
        .reduce((pre, curr) => pre.concat(curr)).map(item => {
          item.title = item.label;
          return item
        }))
      });
      result.push({
        label: '技能',
        values: [{title: '全部', value: null}]
        .concat(skills.map(item => {
          item.title = item.skillname;
          item.value = item.id;
          return item;
        }))
      })
      return result
    },
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 13,
      title: '兼职人才大厅',
      type: 13
    });
  },
  beforeMount() {
    this.loadRouterData();
    this.partTimeConfig.loadData();
    this.partTimeConfig.loadCondition();
    this.partTimeConfig.loadRecommend();
  }
}
</script>

<style scoped lang="scss">
.price-input-container {
  /deep/ {
    .el-input {
      width: 60px;
    }

    .el-input__inner {
      padding: 0 0 0 20px;
    }

    .el-input__prefix {
      div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.site-tag {
  overflow: hidden;
  position: relative;

  &:after {
    content: '兼职';
    display: block;
    position: absolute;
    font-size: 12px;
    background-color: rgba(255, 153, 0, .5);
    color: white;
    line-height: 20px;
    text-align: center;
    right: 0;
    top: 0;
    z-Index: 2;
    padding: 0 3em;
    transform-origin: left bottom;
    transform: translate(29.29%, -100%) rotate(45deg);
    text-indent: 0;
  }

}

.slider-card-title {
  padding-left: 15px;
  border-left: 3px solid $main-color;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
}

.rich-text {
  & /deep/ p {
    display: inline-block;
  }
}
</style>
